.intro-wrap {
  font-family: "Roboto";
  width: 100%;
  position: fixed;
  letter-spacing: 0.15em;
  font-size: 15.22px;
  touch-action: pan-x pan-y;
  z-index: 1;
}

.intro-wrap a {
  font-family: "Roboto";
  font-weight: bold;
  color: black;
}

.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  border-radius: 4px;
  z-index: 100;
  display: flex;
  align-items: center;
}

.loading {
  position: relative;
  display: inline-block;
  width: 100%;
  width: 100vw;
  margin-left: 80px;
  margin-right: 80px;
  height: 10px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.loadingfill {
  content: "";
  position: absolute;
  left: 0;
  width: 50%;
  height: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* animation: load 5s infinite; */
  background: #000;
}
.footer-container {
  display: grid;
  min-height: 100vh;
  width: 100%;
  z-index: 101;
  background: none;
  grid-template-rows: 1fr auto;
  align-items: end;
  padding: 27px 20px;
  justify-content: center;
}

.footer {
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  padding: 15.13px 23px 15.13px 23px;
  max-width: 1900px;
  width: 100%;
  /* margin: 0 auto; */
  margin-bottom: 25px;

}

@media (max-width: 501px) {
  .footer-container {
    width: 100vw;
    padding: 20px;
  }

  .footer {
    min-width: 310px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
.footerText {
  /* padding: 0px 20px; */
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 97.5%;
  letter-spacing: 3.25px;
  text-transform: uppercase;
  justify-self: center;
  align-self: center;
  width:auto;
}

.footerText a {
  font-weight: 900;
  text-underline-offset: 10px;
  text-decoration-thickness: 3px;
}

@media (max-width: 768px) {
  .footerText {
    font-size: 20.3px;
    line-height: 131%;
    letter-spacing: 2.7px;
    /* padding:0px 10px; */
  }
  .footerText a {
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
}
.footerText a:hover {
  color:#ED1E23;
}
.fade {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*SPINNER*/

.spinner {
  width: 60px;
  height: 60px;
  background-color: #ed1e23;

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
