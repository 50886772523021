.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.scroll-to-top img {
  width: auto;
  height: auto;
  max-width: 100%;
  display: block;
}

@media (hover: hover) {
  .scroll-to-top:hover {
    transform: translateY(-2px);
  }
}
