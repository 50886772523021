/* ==========================================================================
   Imports
   ========================================================================== */
@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

/* ==========================================================================
   Base styles
   ========================================================================== */
html {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
}

body {
  margin:0;
  background-color: #fff;
}

strong {
  font-weight: 500;
}

a,
a:active,
a:visited {
  color: #000;
}

/* ==========================================================================
   Layout
   ========================================================================== */
#root .content-container {
  width: 100%;
}

main {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1170px;
  min-height: 38vh;
  margin-bottom: 80px;
}

/* ==========================================================================
   Typography
   ========================================================================== */
.heading {
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-transform: uppercase;
  width: 100%;
}

.heading-no-pad {
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-transform: uppercase;
  width: 100%;
}

.heading .red-heading {
  color: #ed1e23;
}

.subsection {
  margin-top: 45px;
  /* margin-bottom: 425px; */
  margin-bottom: 40px;
  width: 100%;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.subsection-heading {
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

/* ==========================================================================
   Components
   ========================================================================== */
/* Who We Are Section */
.who-we-are-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-top: 60px;
}

.who-we-are-section p span {
  display: block;
  margin-top: 37px;
}

@media (min-width: 768px) {
  
  .who-we-are-section {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .who-we-are-section .we-text {
    flex: 1;
    /* padding-right: 2rem; */
  }

  .who-we-are-section .we-image {
    flex: 1;
    padding-top: 21px;
  }
}

/* Logo */
.we-logos {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

/* Video container */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.vimeo-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   Header
   ========================================================================== */
header {
  display: flex;
  max-width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

/* Large screens */
@media only screen and (max-width: 1200px) {
  /* No specific styles needed */
}

/* Medium screens */
@media only screen and (max-width: 900px) {
  .subsection {
    margin-top: 35px;
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 0px;
    width: auto;
    max-width: calc(100% - 36px);
    /* Safari-specific fixes */
    -webkit-flex-basis: 100% !important;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .subsection-heading {
    font-size: 26px;

  }
  
  .who-we-are-section p span {
    display: block;
    margin-top: 18px;
  }
  .heading {
    font-size: 60px;
    /* line-height: 30px; */
    width: 98%;
  }

  .heading-no-pad {
    font-size: 48px;
    line-height: 40px;
  }
}

.who-we-are {
  margin-bottom: 10px;
  font-size: 42px;
}

.what-we-do {
  margin-top: 35px;
  margin-left: 0px;
  font-size: 42px;
}
.how-we-work {
  font-size: 42px;
}

/* Mobile screens */
@media only screen and (max-width: 480px) {
  html {
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }

  .heading {
    font-size: 48px;
    line-height: 40px;
    width: 98%;
  }

  /* Content spacing adjustments */
  .we-text {
    margin-left: 3px;
  }

  .who-we-are {
    margin: 62px auto 17px -1px;
    font-size: 26px;
  }

  .what-we-do {
    margin-top: 40px;
    /* margin-left: 5px; */
    font-size: 26px;
  }
  .how-we-work {
    font-size: 26px;
  }

  .we-logos {
    margin-top: 0px;
  }

  .what-we-do > p {
    margin-top: 17px;
  }

  .icon-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 15px;
  }
}

.header-logo {
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 90px;
}

/* ==========================================================================
   Header Components
   ========================================================================== */
.header-logo img {
  width: 670px;
}

.header-logo .mobile-logo {
  display: none;
}

.header-nav {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  gap: clamp(0px, 4vw, 50px);
}

.header-nav a {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 15px;
}

.header-nav a.active {
  box-shadow: 0px 0px 10px 0px #00000026;
  border-radius: 5px;
  color: #ed1e23;
}

.header-nav br {
  display: none;
}

/* ==========================================================================
   Footer Components
   ========================================================================== */
footer {
  background-color: #000;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15em;
  padding: 60px 0;
  /* margin-top: 112px; */
  text-transform: uppercase;
}

.footer-wrap {
  max-width: 1170px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer-item {
  padding: 0 30px;
}

.footer-wrap a {
  color: #fff;
  text-decoration: none;
  line-height: 32px;
}

.footer-wrap .footer-social-icon img {
  vertical-align: middle;
  margin-left: 24px;
  height: 27px;
}

.footer-wrap .footer-item.mobile-social {
  display: none;
}

/* ==========================================================================
   We Page Components
   ========================================================================== */
.we-wrap p {
  margin: 0;
}

.we-wrap .heading-no-pad + p {
  padding-top: 10px;
}
.we-text.two-column .column ul {
  margin-left: 35px;
  padding-left: 0;
  margin-top: 15px;
}

.we-text.two-column .column ul li {
  margin-bottom: 22px;
}
.we-wrap .we-hero {
  width: 100%;
}

.we-text.two-column {
  padding-top: 70px;
}

/* ==========================================================================
   Media Queries - Header
   ========================================================================== */
@media only screen and (max-width: 768px) {
  .subsection {
    display: block;
    clear: both;
  }
  .proj-wrap  {
    padding: 10px 15px;
  }
  .we-wrap p {
    margin: 0;
    margin-top: 15px;
  }
  .we-text.two-column .column ul {
    margin-left: 25px;
    padding-left: 0;
    margin-top: 10px;
  }
  .we-text.two-column .column ul li {
    margin-bottom: 7px;
  }
  .we-text.two-column {
    padding-top: 106px;
  }
  .we-wrap .heading-no-pad + p {
    padding-top: 0px;
  }
  .header-logo .desktop-logo {
    display: none;
  }

  .header-logo .mobile-logo {
    display: block;
    width: 102px;
    height: 80px;
    margin: 0 auto;
  }

  .header-logo img {
    width: 90%;
  }
/* }

@media only screen and (max-width: 480px) { */
  .header-logo {
    margin-top: 27px;
  }

  .header-nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    gap: 0;
  }

  .header-nav span {
    flex: 1;
  }

  .header-nav a {
    margin: 0;
  }
}

/* ==========================================================================
   Media Queries - Footer
   ========================================================================== */
@media only screen and (max-width: 1200px) {
  footer {
    max-width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  footer {
    margin-top: 50px;
  }

  .footer-wrap {
    flex-direction: column;
    align-items: center;
  }

  .footer-item {
    margin-bottom: 37px;
  }

  .footer-wrap .footer-social-icon img {
    margin-left: 10px;
    display: none;
  }

  .footer-wrap .footer-item.mobile-social {
    display: initial;
  }

  .footer-wrap .footer-item.mobile-social .footer-social-icon img {
    margin-left: 10px;
    display: inline-block;
  }

  .footer-wrap a {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15em;
  }
}

/* ==========================================================================
   Media Queries - We Page
   ========================================================================== */
@media only screen and (max-width: 900px) {
  .we-text.two-column {
    padding-top: 0;
  }
}

.icon-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  max-width: 100%;
  overflow-x: hidden;
  margin-top: 15px;
}

@media only screen and (max-width: 900px) {
  .icon-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media only screen and (max-width: 900px) {
  .icon-grid {
    margin-left: 10px;
    margin-right: 10px;
  }
} */

.icon-grid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 1.3;
  width: 100%;
  min-width: 0;
}

.icon-grid-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 5px;
  max-width: 100%;
}

.icon-grid-item > span,
.icon-grid-item > div {
  /* height: 62px; */
  font-size: 17px;
  line-height: 1.3;
  text-align: left;
}


/* .we-wrap {
  margin: 0 15px;
} */
@media only screen and (max-width: 900px) {
  .we-wrap {
    margin: 0 15px !important;
    margin-bottom: 30px !important;
  }
  
  .icon-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 55px;
  }
  .icon-grid-item {
    width: calc(50% - 8px);
  }
  
}

@media only screen and (max-width: 480px) {
  

  .we-wrap {
    margin: 65px 15px 0 15px;
  }
  .we-wrap .we-text {
    margin-right: 0;
  }
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    column-gap: 20px;
  }
  .icon-grid-item {
    width: auto;
    
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 0;
  }

  .icon-grid-item span {
    font-size: 11px;
  }
  .icon-grid-item img {
    width: auto;
  }
}

/*END WE PAGE*/

/*DIGITAL PHYSICAL PAGE*/

.link-grid-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 16px;
}

.link-grid-item {
  flex: 0 0 calc(33.333% - 10px);
  position: relative;
  aspect-ratio: 380/260;
}

.link-grid-item.link-grid-item-end-3 {
  margin-right: 0;
}

.link-grid-item img {
  width: 100%;
  display: block;
}

.item-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(235, 35, 45, 0.85);
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  line-height: 100%;
}

.item-overlay:hover {
  opacity: 1;
}

.item-overlay a {
  text-decoration: none;
  transform: translateY(-20px);
  transition: all 0.6s ease;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  margin-left: 10px;
  margin-right: 10px;
}

.item-overlay:hover a {
  transform: translateY(0px);
}

.item-line {
  display: block;
  margin-bottom: 11px;
  padding-bottom: 10px;
  position: relative;
  width: 100%;
}

.item-line::after {
  position: absolute;
  content: "";
  width: 45px;
  border-bottom: 2px solid #ffffff;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.proj-header {
  font-size: 42px;
  letter-spacing: 0.3px;
}

@media only screen and (max-width: 1200px) {
  .link-grid-item {
    flex: 0 0 calc(33.333% - 10px);
  }
  .icon-grid-item {
  }
}


.proj-header {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  text-transform: uppercase;

  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.1px;
  text-align: left;
}

@media only screen and (max-width: 480px) {
  .proj-header {
    font-size: 26px;
  }
  .link-grid-wrap {
    margin-top: 15px;
  }
  .link-grid-item {
    flex: 0 0 100%;
  }
  .icon-grid-item {
  }
}

/*END DIGITAL PHYSICAL PAGE*/

/* PROJECT PAGE */

.proj-wrap {
  width: 100%;
  margin-bottom: 250px;
}



.proj-client {
  color: #ed1e23;
  display: inline-block;
  margin-right: 20px;
}

.proj-name {
  display: inline-block;
}

.proj-hero {
  width: 100%;
  margin-bottom: 25px;
}

.proj-featured {
  /* margin-left: 10px;
  margin-right: 10px; */
  box-sizing: border-box;
}

.proj-featured img {
  width: 100%;
  display: block;
}

.proj-m-featured {
  display: none;
}

.proj-text {
  box-sizing: border-box;
  margin: 36.5px 10px 0px 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  width: 80%;
}

.proj-stats {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  color: #a2a2a2;
}

.stat {
  text-align: left;
  margin-right: 25px;
  margin-bottom: 25px;
}
.stat-num {
  font-size: 34px;
  line-height: 100%;
}
.stat-name {
  font-size: 15px;
  line-height: 100%;
}

.gallery {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 24px;
}
.gallery-item {
  box-sizing: border-box;
  float: left;
  /* padding: 0 10px 20px 10px; */
}

.gallery-item.full {
  width: 100%;
}

.gallery-item.half {
  width: calc(50% - 12px) !important;
}
.gallery-item.onethird {
  width: calc(33.33% - 16px) !important;
}

.gallery-item.twothird {
  width: calc(66.67% - 8px) !important;
}

.gallery-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-item span {
  display: block;
  width: 100%;
  height: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 1.55172px;
  text-transform: uppercase;
  margin-top: 24px;

}

@media only screen and (max-width: 768px) {
  .proj-header-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gallery{
    gap: 10px;
  }
  .proj-hero {
    flex-direction: column;
    /* margin-left: 15px;
    margin-right: 15px; */
  }
  .proj-featured {
    margin-right: 0;
    padding-right: 10px;
  }
  .proj-text {
    margin-left: 10px;
    margin-top: 25px;
  }
  .gallery-item,
  .gallery-item.half,
  .gallery-item.onethird,
  .gallery-item.twothird {
    width: 100% !important;
    padding: 0 0 20px 0;
  }

  .gallery-item img {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }

  .gallery-item span {
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 480px) {


  .proj-m-featured {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .proj-m-featured img {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .proj-featured {
    display: none;
  }

  .proj-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 14px 0 30px 10px;
  }
}

/* END PROJECT PAGE */

/*EXAMPLES*/

/* .hero-callout {
  background-image: url(../assets/bg-arrow.png);
}*/

/* styles for Retina-type displays */
/*
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (-moz-min-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 900px),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 900px),
  only screen and (min-resolution: 192dpi) and (min-width: 900px),
  only screen and (min-resolution: 2dppx) and (min-width: 900px) {
 
  .slide0 {
    background-image: url("../assets/slide0@2x.png");
  }
  .slide1 {
    background-image: url("../assets/slide1@2x.png");
  }
}*/

.two-column {
  display: flex;
  gap: 34px;
  align-items: flex-start;
}

.two-column .column {
  flex: 1;
}

.two-column .column img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 900px) {
  .two-column {
    gap: 12px;
  }
  
  .two-column {
    flex-direction: column;
  }
  
  .two-column .column {
    width: 100%;
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}