.intro-wrap {
  position: fixed;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.intro-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.intro-layer1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  max-width: 100%;
  height: auto;
}

.intro-layer2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

/* Ensure container takes full viewport */
/* Footer container should be above the background */
.container {
  position: relative;
  z-index: 2;
}

.landing-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.landing {
  position: absolute;
  width: 1920px;
  height: 1080px;
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 3;
}

.landing > * {
  transform-origin: top left;
  transform: scale(var(--scale-factor, 1));
}

@media screen {
  .landing {
    --scale-factor: min(
      100vw / 1920,
      100vh / 1080
    );
  }
}

.landing img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.landing .proactiv {
  height: 161px;
  left: 0;
  position: absolute;
  top: 0;
  width: 156px;
}

.landing .ibm {
  height: 161px;
  left: 448px;
  position: absolute;
  top: 0;
  width: 289px;
}

.landing .ole {
  height: 161px;
  left: 156px;
  position: absolute;
  top: 0;
  width: 292px;
}

.landing .valentino {
  height: 191px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 476px;
  width: 238px;
}

.landing .ralph-lauren {
  height: 158px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 161px;
  width: 263px;
}

.landing .overlap {
  height: 413px;
  left: 0;
  position: absolute;
  top: 667px;
  width: 620px;
}

.landing .overlap-group {
  height: 413px;
  left: 0;
  position: absolute;
  top: 0;
  width: 620px;
}

.landing .birchbox {
  height: 212px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 332px;
}

.landing .pringles {
  height: 228px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 185px;
  width: 288px;
}

.landing .carrie {
  height: 201px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 332px;
}

.landing .plated {
  height: 185px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 288px;
}

.landing .GHD {
  height: 232px;
  left: 1201px;
  position: absolute;
  top: 593px;
  width: 173px;
}

/* .landing .th-lg {
  height: 132px;
  left: 1027px;
  position: absolute;
  top: 343px;
  width: 110px;
} */

.landing .div {
  height: 191px;
  left: 238px;
  position: absolute;
  top: 476px;
  width: 499px;
}

.landing .jake-and-coke {
  height: 191px;
  left: 210px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 289px;
}

.landing .special-k {
  height: 191px;
  left: 0;
  position: absolute;
  top: 0;
  width: 210px;
}

.landing .makers-mark {
  height: 227px;
  left: 1748px;
  position: absolute;
  top: 0;
  width: 172px;
}

.landing .jetblue-bermuda {
  height: 157px;
  left: 308px;
  position: absolute;
  top: 319px;
  width: 218px;
}

.landing .nike {
  height: 227px;
  left: 1200px;
  position: absolute;
  top: 0;
  width: 319px;
}

.landing .elemis {
  height: 112px;
  left: 737px;
  position: absolute;
  top: 0;
  width: 174px;
}

.landing .overlap-2 {
  height: 598px;
  left: 1374px;
  position: absolute;
  top: 227px;
  width: 546px;
}

.landing .overlap-3 {
  height: 370px;
  left: 59px;
  position: absolute;
  top: 0;
  width: 487px;
}

.landing .overlap-4 {
  height: 366px;
  left: 0;
  position: absolute;
  top: 0;
  width: 270px;
}

.landing .big-square {
  height: 173px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 193px;
  width: 231px;
}

.landing .lush {
  height: 195px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 270px;
}

.landing .lindt {
  height: 175px;
  left: 231px;
  position: absolute;
  top: 195px;
  width: 256px;
}

.landing .wendys {
  height: 195px;
  left: 329px;
  position: absolute;
  top: 0;
  width: 217px;
}

.landing .harrys {
  height: 232px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 366px;
  width: 290px;
}

.landing .converse {
  height: 169px;
  left: 911px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 289px;
}

.landing .tjmaxx {
  height: 175px;
  left: 931px;
  position: absolute;
  top: 667px;
  width: 270px;
}

.landing .cetaphil {
  height: 231px;
  left: 737px;
  object-fit: cover;
  position: absolute;
  top: 112px;
  width: 174px;
}

.landing .narcon-house {
  height: 255px;
  left: 1201px;
  position: absolute;
  top: 825px;
  width: 329px;
}

.landing .olly-girlsroom {
  height: 104px;
  left: 526px;
  position: absolute;
  top: 372px;
  width: 211px;
}

/* .landing .img {
  height: 133px;
  left: 1027px;
  position: absolute;
  top: 534px;
  width: 110px;
} */

.landing .ikea {
  height: 112px;
  left: 1200px;
  position: absolute;
  top: 481px;
  width: 233px;
}

.landing .overlap-group-2 {
  height: 254px;
  left: 1200px;
  position: absolute;
  top: 227px;
  width: 233px;
}

.landing .knorr {
  height: 98px;
  left: 0;
  position: absolute;
  top: 0;
  width: 233px;
}

.landing .e-pc {
  height: 157px;
  left: 0;
  position: absolute;
  top: 97px;
  width: 233px;
}

.landing .big-long-rec {
  height: 175px;
  left: 620px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 311px;
}

.landing .benefit {
  height: 158px;
  left: 263px;
  object-fit: cover;
  position: absolute;
  top: 161px;
  width: 263px;
}

.landing .ralph-lauren-avicii {
  height: 174px;
  left: 911px;
  position: absolute;
  top: 169px;
  width: 289px;
}

/* .landing .th-lg-2 {
  height: 268px;
  left: 883px;
  position: absolute;
  top: 399px;
  width: 80px;
} */

.landing .th-lg-3 {
  height: 325px;
  left: 736px;
  position: absolute;
  top: 343px;
  width: auto;
}
.landing .th-logo {
  height: 70px;
  left: 785px;
  position: absolute;
  top: 469px;
  width: auto;
}
.landing .dyson {
  height: 211px;
  left: 526px;
  object-fit: cover;
  position: absolute;
  top: 161px;
  width: 211px;
}

.landing .scents-of-self {
  height: 227px;
  left: 1519px;
  position: absolute;
  top: 0;
  width: 229px;
}

.landing .peloton {
  height: 157px;
  left: 0;
  position: absolute;
  top: 319px;
  width: 308px;
}

.landing .rec {
  height: 228px;
  left: 1664px;
  position: absolute;
  top: 597px;
  width: 256px;
}

.landing .soap-glory {
  height: 255px;
  left: 1530px;
  object-fit: cover;
  position: absolute;
  top: 825px;
  width: 198px;
}

.landing .kidney-island {
  height: 255px;
  left: 1728px;
  object-fit: cover;
  position: absolute;
  top: 825px;
  width: 192px;
}

.landing .union {
  height: 238px;
  left: 1037px;
  position: absolute;
  top: 842px;
  width: 164px;
}

.landing .s-g {
  height: 238px;
  left: 620px;
  position: absolute;
  top: 842px;
  width: 216px;
}

.landing .roseinc {
  height: 138px;
  left: 836px;
  position: absolute;
  top: 842px;
  width: 201px;
}

.landing .shapewoman {
  height: 100px;
  left: 836px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 201px;
}